import '../css/index.scss'

// import App from './components/App.js'
import SetupDom from './modules/setupdom.js'

document.addEventListener('DOMContentLoaded', (event) => {
  SetupDom().then(() => {
    // App()
  })
})
