import {
  slice
} from './helpers'

const toRoman = (num) => {
  let result = ''
  const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
  const roman = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I']
  let i = -1
  while (++i <= decimal.length) {
    while (num % decimal[i] < num) {
      result += roman[i]
      num -= decimal[i]
    }
  }
  return result
}

const SetupDom = () => {
  return new Promise((resolve, reject) => {
    const $thumbStories = slice(document.querySelectorAll('.about .thumb-story'))

    if (!$thumbStories.length) {
      reject(new Error('no thumb-story found'))
    }
    $thumbStories.forEach((story, index) => {
      const romanNumeral = toRoman(index + 1)
      story.setAttribute('data-thumb-story', romanNumeral)
      slice(story.querySelectorAll('.thumb-slide')).forEach(slide => {
        let dataSlide = slide.querySelector('img').getAttribute('data-slide')
        dataSlide = dataSlide.replace(/,/g, '')
        dataSlide = dataSlide.replace(/s/g, romanNumeral)

        slide.setAttribute('data-thumb-slide', dataSlide)
      })
    })

    const $stories = slice(document.querySelectorAll('.stories .story'))
    $stories.forEach((story, index) => {
      const romanNumeral = toRoman(index + 1)

      story.setAttribute('data-story', romanNumeral)

      slice(story.querySelectorAll('.canvas-wrap')).forEach(slide => {
        const image = slide.querySelector('img')
        if (image) {
          let dataClass = image.getAttribute('data-class')
          let dataSlide = image.getAttribute('data-slide')
          const dataSlideNumber = []
          dataSlide.split(',').forEach(data => {
            dataSlideNumber.push(parseInt(data.replace('s_', '')))
          })
          const dataSlideLast = Math.max.apply(null, dataSlideNumber)

          dataSlide = dataSlide.replace(/,/g, ' ')
          dataSlide = dataSlide.replace(/s/g, romanNumeral)

          slide.className = `${dataClass} hidden`
          slide.setAttribute('data-slide', dataSlide)
          story.setAttribute('data-last-slide', dataSlideLast)
        } else if (slide.querySelector('iframe')) {
          slide.setAttribute('data-slide', story.getAttribute('data-story') + '_1')
          story.setAttribute('data-last-slide', 1)
        }
      })
    })

    const $storyLink = slice(document.querySelectorAll('.story-link-wrap'))

    $storyLink.forEach((link, index) => {
      link.querySelector('.story-link').setAttribute('data-link-story', toRoman(index + 1))
    })

    resolve()
  })
}

export default SetupDom
